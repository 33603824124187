<template>
    <div v-if="showFindHouse" class="modal">
        <div class="modal-inner black">
            <a href="javascript:;" @click="$emit('update-show-find-house',false)" class="close-btn">
                <i class="el-icon-close size14 black  bold"></i>
            </a>
            <h2 class="mb10 size24 bold">帮我找房</h2>
            <div class="nodes">
                <hr class="hr" />
                <ul class="row icon-container">
                    <li
                        v-for="node in nodes"
                        :key="node.key"
                        class="flex1 column c_center"
                    >
                        <img v-if="node.choose"  src="@/assets/imgs/app_icon_current_node.png" alt="" />
                        <img v-else  src="@/assets/imgs/app_icon_defalut_node.png" alt="" />
                        <span class="size14" :class="[node.choose ?'blue':'whiteGray']">{{node.title}}</span>
                    </li>
                </ul>
            </div>
            <div v-if="current === 1" class="price">
                <span class="price-title size17">您的首付预算是多少?</span>
                <div class="price-text center size22 blue">
                    <span class="min-value">{{form.range[0]}}万</span>
                    <span class="value-hr">-</span>
                    <span class="max-value">{{form.range[1]}}万</span>
                </div>
                <div class="price-range">
                    <el-slider
                        v-model="form.range"
                        range
                        show-stops
                        :step="10"
                        :max="500"
                        :marks="marks"
                    />
                </div>
            </div>
            <ul v-if="current === 2" class="house-type row wrap">
                <li v-for="item in formParam.room.data" :key="item.value">{{item.name}}</li>
            </ul>
            <div  v-if="current === 3 && areas.length" class="area">
                <span class="price-title size17">您的意向购房区域是哪里?</span>
                <ul class="area-wrap">
                    <li v-for="area in areas" :key="area.value">{{area.name}}</li>
                </ul>
            </div>
            <div v-if="current === 4" class="login-subit">
                <span class="price-title size17">您的联系方式?</span>
                <p class="private-info">您的个人信息将会被严格保密，请放心填写</p>
                <ul>
                    <li class="phone-wrap relative">
                        <input :maxlength="11" v-model="form.phone" placeholder="请输入手机号" type="text" />
                        <a v-if="form.phone" href="javascript:;" class="clear-btn">
                            <i class="el-icon-close size10 white"></i>
                        </a>
                    </li>
                    <li v-if="form.phone" class="row relative sms-captcha-input">
                        <div class="relative row">
                            <input :maxlength="4" v-model="form.phone" placeholder="请输入短信验证码" type="text" />
                            <a v-if="verificationCode" href="javascript:;" class="clear-btn">
                                <i class="el-icon-close size10 white"></i>
                            </a>
                        </div>
                        <span class="hr"></span>
                        <a href="javascript:;" class="sms-send-btn">获取验证码</a>
                    </li>
                    <li v-if="errMsg" class="err-msg size14 red">{{errMsg}}</li>
                    <li class="read-protocol row whiteGray size16">
                        <div
                            class="box flex c_center mr5"
                            :class="[isReadAgreement?'choose-bottom-rengion':'whiteBG']"
                        >
                            <i v-show="isReadAgreement" class="el-icon-check white" ></i>
                        </div>
                        我已阅读
                        <router-link class="blue" to="/">《用户协议》</router-link>
                        (点击可取消)
                    </li>
                </ul>
            </div>
            <div class="footer">
                <div v-if="current !== 4 && current > 1" class="btn prev">上一步</div>
                <div v-if="current !== 4" class="btn next">下一步</div>
                <div @click="submitForm" v-if="current === 4" class="btn submit">立即提交</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        form:{
            type:Object,
            require: true
        },
        showFindHouse:{
            type:Boolean,
            require:true
        },
        formParam:{
            type:Object,
            require:true
        }
    },
    computed:{
        areas() {
            const { data } = this.formParam.region
            const result = []
            if(data && data.length) {
                for(let i = 0 ;i <  data.length;i++) {
                    if(data[i].children && data[i].children.length) {
                        for(let j = 0;j< data[i].children.length;j++) {
                            result.push(data[i].children[j])
                        }
                    }
                }
            }
            return result
        },
    },
    data() {

        return {
            nodes:[
                {
                    title:"首付预算",
                    key:'payments',
                    type:1,
                    choose:true,
                },
                {
                    title:"户型要求",
                    key:'houseType',
                    type:2,
                    choose:false,
                },
                {
                    title:"意向片区",
                    key:'area',
                    type:3,
                    choose:false,
                },
                {
                    title:"联系方式",
                    key:'phone',
                    type:4,
                    choose:false,
                },
            ],
            current:4,
            marks: {
                0: '0万',
                500: '500万',
            },
            errMsg:"",
            isReadAgreement:true,
            verificationCode:""

        }
    },
    components:{
    },
    methods:{
        submitForm() {

        },

    }
}
</script>

<style lang="less" scoped>
.blue{
    color: #3072f6
}
.modal-inner {
    border-radius: 4px;
    padding: 44px 45px 22px;
    width: 445px;
    height: 591px;
    box-sizing: border-box;
    .close-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        border: 20px solid transparent
    }
    .nodes {
        position: relative;
        margin-top: 37px;
        margin-bottom: 78px;
        .hr {
            background: #f3f4f5;
            border-radius: 5px;
            border-width: 0;
            height: 2px;
        }
        .icon-container {
            position: absolute;
            white-space: nowrap;
            width: 100%;
            height: 52px;
            top: -15px;
            left: 0;
            text-align: center;
            img {
                width: 26px;
                height: 26px;
            }
        }
    }
    .price-title {
        letter-spacing: .2px;
        display: inline-block;
        margin-bottom: 18px;
    }
    .price-text {
        margin-top: 18px;
         span {
            letter-spacing: 0;
            text-align: center;
        }
    }
    .area-wrap {
        height: 188px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-top: 18px;
    }
    .house-type,
    .area-wrap  {
        li {
            width: 70.4px;
            height: 34px;
            line-height: 34px;
            margin-right: 15.5px;
            margin-bottom: 15px;
            background: #f8f8f9;
            border-radius: 2px;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            color: #101d37;
            letter-spacing: .14px;
            text-align: center;
            box-sizing: border-box;
            display: inline-block;
            cursor: pointer;
            transition: all .1s ease-in;
            border: 1px solid transparent;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 10px;
            &.select {
                border: 1px solid #101d37;
                background: #fff;
            }
        }
    }
    .footer {
        position: absolute;
        bottom: 45px;
        overflow: visible;
        left: 45px;
        right: 45px;
        width: auto;
         .btn {
            height: 40px;
            width: 120px;
            border-radius: 30px;
            cursor: pointer;
            font-size: 18px;
            color: #fff;
            letter-spacing: -.3px;
            text-align: center;
            line-height: 40px;
        }
        .prev {
            background: #9399a5;
            float: left;
        }
        .next {
            background: #3072f6;
            float: right;
        }
        .submit {
            position: relative;
            width: 100%;
            height: 60px;
            line-height: 60px;
            background: #3072f6;
            border-radius: 2px;
            &::after {
                position: absolute;
                font-size: 12px;
                color: #9399a5;
                letter-spacing: -0.2px;
                bottom: -23px;
                height: 17px;
                left: 0;
                line-height: 17px;
                content: "提交后有新房顾问致电联系并提供服务"
            }
        }
    }
    .login-subit {
        .private-info {
            font-size: 16px;
            color: #9399a5;
            letter-spacing: -.27px;
            margin-bottom: 16px;
        }

        .phone-wrap,
         .sms-captcha-input {
            border: 1px solid #eeeeee;
        }
        input {
            display: block;
            box-sizing: border-box;
            width: 100%;
            padding: 18px;
            font-size: 18px;
            line-height: 24px;
            color: #101d37;
            border: none;
        }
        .clear-btn {
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 16px;
            background-color: #b7bbc3;
            position: absolute;
            right: 22px;
            top: 50%;
            text-align: center;
            transform: translateY(-50%)

        }
        .sms-captcha-input {
            padding-right: 146px
        }
        .hr {
            position: absolute;
            right: 120px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            height: 16px;
            width: 1px;
            border: 0;
            background-color: #e4e6f0;
            margin: 0;
            padding: 0;
        }
        .sms-send-btn {
            font-size: 16px;
            line-height: 22px;
            position: absolute;
            top: 50%;
            right: 0;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 20px;
        }
        .read-protocol {
            height: 18px;
            line-height: 18px;
            padding: 23px 0;

        }
        .err-msg {
            height: 14px;
            line-height: 14px;
            padding: 24px 0 0;
        }
    }
    .box {
        width: 16px;
        height: 16px;
        border:1px solid #CCCCCC;
        border-radius: 2px;
        box-sizing: border-box;
    }
    .choose-bottom-rengion {
        background: #3072f6;
        border:none
    }

}
</style>


