<template>
    <div class="container row column c_center">
          <ul class="sidebar-wrap black size26">
            <li v-if="user">
              <router-link to="/setting" class="black" target="_blank" >
                <i class="el-icon-star-on"></i>
                <span class="row popup">
                    <i></i>
                    我关注的房源
                </span>
              </router-link>
            </li>
            <li v-else @click="toFavorHouse">
                <i class="el-icon-star-on"></i>
                <span class="row popup">
                    <i></i>
                    我关注的房源
                </span>
            </li>
            <div class="sidebar-line"></div>
            <div class="sidebar-line"></div>
            <li>
                <i class="el-icon-full-screen"></i>
                <span class="popup-qr">
                    <img width="100" :src="downloadUrl" alt="" />
                    <em class="qr-title">{{companyConfig.firstName+ companyConfig.lastName}}APP</em>
                </span>
            </li>
        </ul>
        <ul v-show="showGoBack" class="gotop">
            <li class="row column c_center size10" @click="goBack">
                <i class="el-icon-caret-top size14"></i>
                TOP
                <span class="row popup">
                    <i></i>
                    返回顶部
                </span>
            </li>
        </ul>
    </div>
</template>
<script>
import tool from "@/utils/tool";
import { mapState } from "vuex";

export default {
  props: {
    showGoBack: {
      type: Boolean,
      require: true
    }
  },
  computed: {
    ...mapState(["user","companyConfig"]),
     downloadUrl() {
      return this.companyConfig.downloadUrl
    }
  },
  methods: {
    goBack() {
      tool.animate(window, 0);
    },
    toFavorHouse() {
      if (!this.user) {
        this.$store.commit("updateState", {
          data: true,
          key: "showLogin"
        });
        return;
      }
    }
  }
};
</script>
<style lang="less" scoped>
.container {
  position: fixed;
  right: 0;
  top: 0;
  width: 38px;
  height: 100%;
  background: #fff;
  z-index: 1000;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  .sidebar-line {
    width: 19px;
    height: 1px;
    margin: 7px auto;
    background: #ccc;
    text-align: center;
  }
  .sidebar-wrap,
  .gotop {
    width: 100%;
    li {
      position: relative;
      margin: 7px 0;
      height: 38px;
      width: 100%;
      text-align: center;
      cursor: pointer;
      transition: all 300ms;
      span {
        position: absolute;
        right: 48px;
        top: 0;
        display: none;
        padding: 0 14px;
        line-height: 38px;
        color: #ffffff;
        font-size: 12px;
        background: #00ae66;
        white-space: nowrap;
        .qr-title {
          display: block;
          padding-top: 8px;
          color: #000;
          line-height: 12px;
          font-size: 12px;
          text-align: center;
          font-style: italic;
        }
        &.popup {
          height: 38px;
          display: none;
          opacity: 0;
          right: 48px;
        }
        &.popup-qr {
          top: -30px;
          padding: 8px;
          background: #fff;
        }
        i {
          position: absolute;
          right: 0;
          top: 50%;
          width: 0;
          height: 0;
          margin: -5px -10px 0 0;
          font-size: 0;
          border: 5px solid transparent;
          border-left-color: #00ae66;
        }
      }
      &:hover {
        background-color: #394043;
        color: #ffffff;
        a {
          color: #ffffff;
        }
        span {
          display: block;
          opacity: 1;
          right: 38px;
        }
      }
    }
  }
  .gotop {
    position: absolute;
    bottom: 50px;
  }
}
</style>


