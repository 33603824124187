import { render, staticRenderFns } from "./hot-estate-item.vue?vue&type=template&id=31402670&scoped=true&"
import script from "./hot-estate-item.vue?vue&type=script&lang=js&"
export * from "./hot-estate-item.vue?vue&type=script&lang=js&"
import style0 from "./hot-estate-item.vue?vue&type=style&index=0&id=31402670&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31402670",
  null
  
)

export default component.exports